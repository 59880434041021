import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ParticlesBackground } from './components/ParticlesBackground';
import { Hero } from './components/Hero';
import { AdminPanel } from './components/admin';
import { PersonalizedInvite } from './components/PersonalizedInvite';

// Configure future flags for React Router v7
const routerConfig = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
};

// Wrapper component to access location
function InviteRoute() {
  const location = useLocation();
  const inviterId = location.pathname.split('/').pop() || '';
  
  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <div className="absolute inset-0 z-0">
        <ParticlesBackground />
      </div>
      <div className="relative z-10">
        <PersonalizedInvite inviterId={inviterId} />
      </div>
    </div>
  );
}

function App() {
  return (
    <Router {...routerConfig}>
      <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
        <div className="absolute inset-0 z-0">
          <ParticlesBackground />
        </div>
        <div className="relative z-10">
          <Routes>
            <Route path="/admin/*" element={<AdminPanel />} />
            <Route path="/te-invita/:inviterId" element={<InviteRoute />} />
            <Route path="/" element={<Hero />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
