import React from 'react';
import { Menu, RefreshCw } from 'lucide-react';
import { AdminSidebar } from './AdminSidebar';
import { DatabaseActions } from './DatabaseActions';
import { StatsCards } from './StatsCards';
import { WaitlistTable } from './WaitlistTable';
import { EditModal } from './EditModal';
import { Dashboard, Analytics, PlaceholderSection, EmailSection } from './sections';
import { ParticlesBackground } from '../ParticlesBackground';
import type { WaitlistEntry, EditFormData } from './EditModal';

interface AdminPanelContentProps {
  waitlistData: {
    entries: WaitlistEntry[];
    remainingSpots: number;
    totalRegistrations: number;
    dbStatus: {
      status: string;
      details: any;
    };
  } | null;
  error: string;
  editingEntry: WaitlistEntry | null;
  isEditModalOpen: boolean;
  activeSection: string;
  isSidebarOpen: boolean;
  onSidebarToggle: () => void;
  onSectionChange: (section: string) => void;
  onLogout: () => void;
  onEdit: (entry: WaitlistEntry) => void;
  onDelete: (id: number) => void;
  onEditModalClose: () => void;
  onEditModalSave: (data: EditFormData) => void;
  onBackup: () => void;
  onReset: () => void;
  onRefreshWaitlist: () => void;
  isRefreshing?: boolean;
}

export const AdminPanelContent: React.FC<AdminPanelContentProps> = ({
  waitlistData,
  error,
  editingEntry,
  isEditModalOpen,
  activeSection,
  isSidebarOpen,
  onSidebarToggle,
  onSectionChange,
  onLogout,
  onEdit,
  onDelete,
  onEditModalClose,
  onEditModalSave,
  onBackup,
  onReset,
  onRefreshWaitlist,
  isRefreshing = false,
}) => {
  const renderSection = () => {
    switch (activeSection) {
      case 'dashboard':
        return <Dashboard />;
      case 'waitlist':
        return (
          <>
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-bold text-white">Lista de așteptare</h1>
              <button
                onClick={onRefreshWaitlist}
                disabled={isRefreshing}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 transition-colors ${
                  isRefreshing ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                <RefreshCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
                <span>{isRefreshing ? 'Se actualizează...' : 'Actualizează'}</span>
              </button>
            </div>

            {waitlistData && (
              <DatabaseActions
                dbStatus={waitlistData.dbStatus}
                onBackup={onBackup}
                onReset={onReset}
              />
            )}

            {error && (
              <div className="mb-8 bg-red-500/10 border border-red-500/20 text-red-400 px-4 py-3 rounded-lg">
                {error}
              </div>
            )}

            {waitlistData && (
              <>
                <StatsCards
                  totalRegistrations={waitlistData.totalRegistrations}
                  remainingSpots={waitlistData.remainingSpots}
                />

                <WaitlistTable
                  entries={waitlistData.entries}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              </>
            )}
          </>
        );
      case 'analytics':
        return <Analytics />;
      case 'emails':
        return <EmailSection />;
      default:
        const titles: { [key: string]: string } = {
          'reports': 'Rapoarte',
          'notifications': 'Notificări',
          'security': 'Jurnal de Securitate',
          'database': 'Bază de Date',
          'settings': 'Setări'
        };
        return <PlaceholderSection title={titles[activeSection] || 'Secțiune Nouă'} />;
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <div className="absolute inset-0">
        <ParticlesBackground />
      </div>
      
      <div className="relative z-10">
        {/* Mobile Menu Button */}
        <button
          onClick={onSidebarToggle}
          className="lg:hidden fixed top-4 left-4 z-50 p-2 bg-gray-800 rounded-lg text-white hover:bg-gray-700 transition-colors"
          aria-label={isSidebarOpen ? "Închide meniul" : "Deschide meniul"}
          role="button"
          aria-controls="sidebar"
        >
          <Menu size={24} />
        </button>

        {/* Sidebar */}
        <div
          id="sidebar"
          role="navigation"
          className={`
            fixed top-0 left-0 h-full w-64 transform transition-transform duration-300 ease-in-out z-40
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
            lg:translate-x-0
          `}
        >
          <AdminSidebar 
            activeSection={activeSection} 
            onSectionChange={(section) => {
              onSectionChange(section);
              onSidebarToggle();
            }}
            onLogout={onLogout}
          />
        </div>

        {/* Overlay */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
            onClick={onSidebarToggle}
            role="presentation"
          />
        )}
        
        {/* Main Content */}
        <main 
          className="lg:ml-64 p-4 lg:p-8"
          role="main"
        >
          {renderSection()}
        </main>

        <EditModal
          entry={editingEntry}
          isOpen={isEditModalOpen}
          onClose={onEditModalClose}
          onSave={onEditModalSave}
        />
      </div>
    </div>
  );
};
