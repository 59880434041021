import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface WaitlistEntry {
  id: number;
  full_name: string;
  email: string;
  phone: string;
  country: string;
  city: string;
  created_at: string;
}

interface EditFormData {
  id?: number;
  full_name: string;
  email: string;
  phone: string;
  country: string;
  city: string;
}

interface EditModalProps {
  entry: WaitlistEntry | null;
  isOpen: boolean;
  onClose: () => void;
  onSave: (entry: EditFormData) => void;
}

export const EditModal: React.FC<EditModalProps> = ({ entry, isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState<EditFormData>({
    full_name: '',
    email: '',
    phone: '',
    country: '',
    city: ''
  });

  useEffect(() => {
    if (entry) {
      setFormData({
        id: entry.id,
        full_name: entry.full_name,
        email: entry.email,
        phone: entry.phone,
        country: entry.country,
        city: entry.city
      });
    }
  }, [entry]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-gradient-to-b from-gray-900 to-gray-800 rounded-xl border border-white/10 p-6 max-w-md w-full"
      >
        <h2 className="text-2xl font-bold text-white mb-4">Editare Înregistrare</h2>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSave(formData);
        }}>
          <div className="space-y-4">
            <input
              type="text"
              value={formData.full_name}
              onChange={(e) => setFormData({ ...formData, full_name: e.target.value })}
              placeholder="Nume Complet"
              className="w-full p-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
            />
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              placeholder="Email"
              className="w-full p-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
            />
            <input
              type="text"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              placeholder="Telefon"
              className="w-full p-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
            />
            <input
              type="text"
              value={formData.country}
              onChange={(e) => setFormData({ ...formData, country: e.target.value })}
              placeholder="Țară"
              className="w-full p-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
            />
            <input
              type="text"
              value={formData.city}
              onChange={(e) => setFormData({ ...formData, city: e.target.value })}
              placeholder="Oraș"
              className="w-full p-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
              aria-label="Anulează editarea"
            >
              Anulare
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg hover:from-blue-600 hover:to-indigo-700 transition-all"
              aria-label="Salvează modificările"
            >
              Salvare
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export type { WaitlistEntry, EditFormData };
