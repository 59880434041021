import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { CountdownTimer } from './CountdownTimer';
import { InfoModal } from './InfoModal';
import { RegistrationModal } from './RegistrationModal';
import { Footer } from './Footer';
import { useInView } from 'react-intersection-observer';
import { HeroLogo } from './HeroLogo';
import { HeroHeader } from './HeroHeader';
import { HeroCategories } from './HeroCategories';
import { ParticlesBackground } from './ParticlesBackground';
import { ReferralFomo } from './ReferralFomo';

export function Hero() {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [error] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [ref] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleStartJourney = () => {
    setIsRegistrationModalOpen(true);
  };

  return (
    <div className="relative min-h-screen">
      <div className="absolute inset-0">
        <ParticlesBackground />
      </div>

      <div className="relative z-10">
        <div className="container mx-auto px-4 pt-16 sm:pt-20 pb-16 sm:pb-20">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <HeroLogo />
            <HeroHeader />

            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.8 }}
              className="mb-12 sm:mb-16"
            >
              <CountdownTimer />
            </motion.div>

            <div className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-6 mb-8 sm:mb-12 px-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsRegistrationModalOpen(true)}
                className="w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg transition-all flex items-center justify-center gap-2 group shadow-lg hover:shadow-blue-500/20"
              >
                Începe Călătoria
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsInfoModalOpen(true)}
                className="w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-4 bg-white/10 hover:bg-white/20 text-white rounded-lg transition-all backdrop-blur-lg border border-white/20 hover:border-white/40 hover:shadow-white/10"
              >
                Află Mai Multe
              </motion.button>
            </div>

            <div className="text-base sm:text-lg text-gray-400 px-4 mb-8">
              {isLoading ? (
                <span>Se încarcă...</span>
              ) : error ? (
                <span className="text-red-400">{error}</span>
              ) : (
                <>
                  <span className="text-blue-400 font-bold">
                    200 de locuri GRATUITE!
                  </span>{' '}
                  <span className="text-white">
                    Înscrie-te acum pentru acces prioritar
                  </span>
                </>
              )}
            </div>

            <HeroCategories />
          </motion.div>
        </div>

        <Footer />
        <InfoModal 
          isOpen={isInfoModalOpen} 
          onClose={() => setIsInfoModalOpen(false)}
          onStartJourney={handleStartJourney}
        />
        <RegistrationModal 
          isOpen={isRegistrationModalOpen} 
          onClose={() => setIsRegistrationModalOpen(false)} 
        />
        <ReferralFomo />
      </div>
    </div>
  );
}
