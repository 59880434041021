import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Users, Star, Shield } from 'lucide-react';
import { Features } from './Features';
import { CTASection } from './CTASection';
import { Footer } from './Footer';
import { RegistrationModal } from './RegistrationModal';
import { InfoModal } from './InfoModal';
import { ReferralFomo } from './ReferralFomo';

interface PersonalizedInviteProps {
  inviterId: string;
}

export function PersonalizedInvite({ inviterId }: PersonalizedInviteProps) {
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  useEffect(() => {
    // Track visitor
    fetch('/api/track-visitor', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        visitorId: localStorage.getItem('visitorId') || Date.now().toString(),
        pageUrl: `/te-invita/${inviterId}`,
        timestamp: new Date().toISOString()
      })
    }).catch(console.error);
  }, [inviterId]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      <div className="container mx-auto px-4 pt-16 sm:pt-20 pb-16 sm:pb-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center"
        >
          {/* Logo Section */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
            className="w-24 h-24 mx-auto mb-8 relative"
          >
            <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500/20 to-indigo-500/20 animate-pulse-slow" />
            <img
              src="/oameni-speciali-logo.png"
              alt="Oameni Speciali"
              className="relative z-10 w-full h-full object-contain"
            />
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.8 }}
            className="text-3xl sm:text-6xl font-bold text-white mb-6 sm:mb-8 leading-tight px-4"
          >
            {inviterId === 'heromind' ? 'HeroMind România te invită' : `${inviterId} te invită`} să te alături
            <span className="block bg-gradient-to-r from-blue-400 to-indigo-500 bg-clip-text text-transparent animate-pulse-slow mt-2">
              {inviterId === 'heromind' ? 'comunității transformaționale în sănătate mintală' : 'viitorului sănătății mintale'}
            </span>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="text-lg sm:text-xl text-gray-300 mb-8 sm:mb-12 max-w-2xl mx-auto leading-relaxed px-4"
          >
            {inviterId === 'heromind' 
              ? 'Devino parte din rețeaua exclusivă HeroMind de profesioniști în sănătate mintală și contribuie la transformarea domeniului în România.'
              : 'Alătură-te unei comunități exclusive de profesioniști în sănătatea mintală și fii parte din schimbarea pe care vrei să o vezi.'
            }
          </motion.p>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.8 }}
            className="space-y-4 mb-12"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsRegistrationModalOpen(true)}
              className="px-8 py-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg transition-all flex items-center justify-center gap-2 group mx-auto"
            >
              <span>{inviterId === 'heromind' ? 'Alătură-te Rețelei HeroMind' : 'Rezervă-ți Locul VIP Acum'}</span>
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </motion.button>
            
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsInfoModalOpen(true)}
              className="px-8 py-4 bg-gradient-to-r from-blue-400/20 to-indigo-500/20 text-blue-400 hover:text-blue-300 rounded-lg transition-all flex items-center justify-center gap-2 group mx-auto text-lg font-medium border border-blue-500/30 hover:border-blue-400/50"
            >
              <span>Află mai multe</span>
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </motion.button>
          </motion.div>

          {/* Trust Indicators */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1 }}
            className="flex flex-wrap justify-center gap-4 mb-12 text-sm text-gray-400"
          >
            <span className="flex items-center gap-1">
              <Users className="w-4 h-4" />
              200+ Membri Activi
            </span>
            <span className="flex items-center gap-1">
              <Star className="w-4 h-4" />
              Comunitate Verificată
            </span>
            <span className="flex items-center gap-1">
              <Shield className="w-4 h-4" />
              Acces Securizat
            </span>
          </motion.div>
        </motion.div>
      </div>

      <Features />
      <div className="container mx-auto px-4 py-12">
        <CTASection />
      </div>
      <Footer />
      
      <ReferralFomo />
      
      <RegistrationModal 
        isOpen={isRegistrationModalOpen} 
        onClose={() => setIsRegistrationModalOpen(false)}
        inviterId={inviterId}
      />
      
      <InfoModal 
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        onStartJourney={() => {
          setIsInfoModalOpen(false);
          setIsRegistrationModalOpen(true);
        }}
      />
    </div>
  );
}
