import React from 'react';
import { Users } from 'lucide-react';

interface StatsCardsProps {
  totalRegistrations: number;
  remainingSpots: number;
}

export const StatsCards: React.FC<StatsCardsProps> = ({
  totalRegistrations,
  remainingSpots,
}) => {
  const occupancyRate = ((200 - remainingSpots) / 200 * 100).toFixed(1);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mt-8">
      <div className="bg-gray-800/50 rounded-lg p-4 sm:p-6">
        <div className="flex items-center gap-4">
          <Users className="text-blue-400 h-5 w-5 sm:h-6 sm:w-6" />
          <div>
            <h3 className="text-sm font-medium text-gray-400">Total Înregistrări</h3>
            <p className="text-xl sm:text-2xl font-bold text-white mt-1">
              {totalRegistrations} <span className="text-sm text-gray-500">↗</span>
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-800/50 rounded-lg p-4 sm:p-6">
        <div className="flex items-center gap-4">
          <Users className="text-blue-400 h-5 w-5 sm:h-6 sm:w-6" />
          <div>
            <h3 className="text-sm font-medium text-gray-400">Locuri Rămase</h3>
            <p className="text-xl sm:text-2xl font-bold text-white mt-1">
              {remainingSpots}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-800/50 rounded-lg p-4 sm:p-6">
        <div className="flex items-center gap-4">
          <Users className="text-blue-400 h-5 w-5 sm:h-6 sm:w-6" />
          <div>
            <h3 className="text-sm font-medium text-gray-400">Grad de Ocupare</h3>
            <p className="text-xl sm:text-2xl font-bold text-white mt-1">
              {occupancyRate}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
