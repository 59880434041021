import { motion, AnimatePresence } from 'framer-motion';
import { X, ArrowRight } from 'lucide-react';

interface InfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onStartJourney: () => void;
}

export function InfoModal({ isOpen, onClose, onStartJourney }: InfoModalProps) {
  if (!isOpen) return null;

  const handleStartJourney = () => {
    onClose();
    onStartJourney();
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4 sm:p-6"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          onClick={(e) => e.stopPropagation()}
          className="bg-gradient-to-b from-gray-900 to-gray-800 rounded-xl border border-white/10 p-6 sm:p-8 max-w-3xl max-h-[90vh] overflow-y-auto relative"
        >
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors"
            aria-label="Închide fereastra"
            title="Închide"
          >
            <X size={24} />
          </button>

          <div className="prose prose-invert max-w-none">
            <h1 className="text-2xl sm:text-3xl font-bold bg-gradient-to-r from-rose-500 to-purple-600 bg-clip-text text-transparent mb-6">
              🌟 OAMENI SPECIALI - Prima Platformă Premium de Networking în Sănătatea Mintală din România
            </h1>

            <p className="text-gray-300 text-lg mb-8">
              Imaginează-ți să faci parte dintr-o comunitate exclusivistă de profesioniști în sănătatea mintală, unde experții își unesc forțele pentru a revoluționa domeniul terapiei în România. Aceasta nu este doar o altă platformă - este viitorul networkingului profesional în sănătatea mintală.
            </p>

            <div className="bg-white/5 rounded-lg p-6 mb-8 border border-white/10">
              <h2 className="text-xl font-bold text-rose-500 mb-4">
                🔥 OPORTUNITATE UNICĂ DE PRE-LANSARE:
              </h2>
              <p className="text-white mb-6">
                Doar primii 200 de profesioniști vor avea acces GRATUIT la membership-ul premium. O șansă care nu se va repeta!
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleStartJourney}
                className="w-full px-6 py-4 bg-gradient-to-r from-rose-500 to-rose-600 text-white rounded-lg transition-all flex items-center justify-center gap-2 group shadow-lg hover:shadow-rose-500/20 relative overflow-hidden"
              >
                <span className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/20 to-white/0 animate-shimmer" />
                <span className="relative flex items-center gap-2 text-lg font-semibold">
                  Începe Călătoria Acum
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </span>
              </motion.button>
              <p className="text-gray-400 text-sm text-center mt-4">
                🎯 Nu rata șansa de a fi printre primii 200 de membri fondatori
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold text-white mb-4">
                👥 CINE SUNTEM:
              </h2>
              <p className="text-gray-300 mb-4">
                Oameni Speciali este un hub exclusivist care reunește elite din:
              </p>
              <ul className="list-none space-y-2 text-gray-300">
                <li>• Psihiatrie</li>
                <li>• Psihoterapie</li>
                <li>• Psihologie</li>
                <li>• Consiliere</li>
                <li>• Coaching</li>
                <li className="text-gray-400">și alte specializări conexe în sănătatea mintală</li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold text-white mb-6">
                ✨ DE CE SĂ TE ALĂTURI ACUM:
              </h2>
              
              <div className="space-y-6">
                <div>
                  <h3 className="text-lg font-semibold text-rose-500 mb-3">1. ACCES EXCLUSIV:</h3>
                  <ul className="list-none space-y-2 text-gray-300">
                    <li>- Networking direct cu cei mai influenți profesioniști din domeniu</li>
                    <li>- Oportunități de colaborare care nu sunt disponibile în altă parte</li>
                    <li>- Resurse premium și instrumente inovatoare</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-rose-500 mb-3">2. CREȘTERE PROFESIONALĂ:</h3>
                  <ul className="list-none space-y-2 text-gray-300">
                    <li>- Vizibilitate în fața unei audiențe de elită</li>
                    <li>- Parteneriate strategice</li>
                    <li>- Dezvoltarea practicii tale prin conexiuni de valoare</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-rose-500 mb-3">3. INOVAȚIE:</h3>
                  <ul className="list-none space-y-2 text-gray-300">
                    <li>- Fii printre pionierii care modelează viitorul sănătății mintale în România</li>
                    <li>- Acces la cele mai noi tendințe și metodologii</li>
                    <li>- Participare la evenimente exclusive</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-white/5 rounded-lg p-6 mb-8 border border-white/10">
              <h2 className="text-xl font-bold text-rose-500 mb-4">
                🎯 BENEFICII SPECIALE PENTRU MEMBRII FONDATORI:
              </h2>
              <ul className="list-none space-y-2 text-gray-300">
                <li>• Membership GRATUIT pe viață pentru primii 200 de membri</li>
                <li>• Badge special "Membru Fondator"</li>
                <li>• Acces prioritar la toate funcționalitățile viitoare</li>
                <li>• Invitații VIP la evenimente exclusive</li>
                <li>• Posibilitatea de a modela direcția platformei</li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold text-white mb-4">
                ⚡ DE CE ACUM:
              </h2>
              <ul className="list-none space-y-2 text-gray-300">
                <li>• Doar 200 de locuri disponibile gratuit</li>
                <li>• Lista de așteptare crește exponențial</li>
                <li>• După lansare, accesul va fi limitat și cu taxă lunară</li>
                <li>• Criteriile de selecție vor deveni mai stricte</li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold text-white mb-4">
                🌟 PENTRU CINE ESTE:
              </h2>
              <ul className="list-none space-y-2 text-gray-300">
                <li>• Profesioniști dedicați care vor să fie în avangarda domeniului</li>
                <li>• Experți care caută conexiuni valoroase</li>
                <li>• Lideri care vor să influențeze viitorul sănătății mintale</li>
                <li>• Practicieni care vor să-și scaleze impactul</li>
              </ul>
            </div>

            <div className="bg-gradient-to-r from-rose-500/10 to-purple-600/10 rounded-lg p-6 mb-8 border border-white/10">
              <h2 className="text-xl font-bold text-white mb-4">
                ⏰ NU RATA ACEASTĂ OPORTUNITATE:
              </h2>
              <p className="text-gray-300">
                Locurile gratuite se ocupă rapid, iar șansa de a fi printre membrii fondatori este unică. După lansarea oficială, accesul va fi restricționat și cu taxă premium.
              </p>
            </div>

            <div className="bg-white/5 rounded-lg p-6 mb-8 border border-white/10">
              <h2 className="text-xl font-bold text-rose-500 mb-4">
                🎁 BONUS SPECIAL DE PRE-LANSARE:
              </h2>
              <p className="text-white mb-4">Primii 50 de membri care se înscriu primesc și:</p>
              <ul className="list-none space-y-2 text-gray-300">
                <li>• Sesiune 1:1 cu un mentor din board-ul consultativ</li>
                <li>• Profil verificat cu badge special</li>
                <li>• Promovare prioritară în comunitate</li>
              </ul>
            </div>

            <div className="text-center mb-8">
              <h2 className="text-xl font-bold text-white mb-4">
                👉 ACȚIONEAZĂ ACUM:
              </h2>
              <p className="text-gray-300">
                Nu lăsa această oportunitate să treacă. Fii printre pionierii care vor revoluționa domeniul sănătății mintale în România.
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleStartJourney}
                className="w-full mt-6 px-6 py-4 bg-gradient-to-r from-rose-500 to-rose-600 text-white rounded-lg transition-all flex items-center justify-center gap-2 group shadow-lg hover:shadow-rose-500/20 relative overflow-hidden"
              >
                <span className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/20 to-white/0 animate-shimmer" />
                <span className="relative flex items-center gap-2 text-lg font-semibold">
                  Începe Călătoria Acum
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </span>
              </motion.button>
            </div>

            <div className="flex items-center justify-center gap-4 text-sm text-gray-400 mb-8">
              <span>🔒 Locuri limitate</span>
              <span>🎯 Acces gratuit doar pentru primii 200</span>
              <span>⭐ Oportunitate unică</span>
            </div>

            <div className="bg-gradient-to-r from-rose-500/10 to-purple-600/10 rounded-lg p-6 border border-white/10">
              <p className="text-gray-300 italic">
                P.S. Gândește-te unde vei fi peste 6 luni: printre liderii care modelează viitorul domeniului sau printre cei care privesc din exterior cum alții innovează și cresc?
              </p>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
