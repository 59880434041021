import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// Create root only once
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Add performance monitoring in development
if (import.meta.env.DEV) {
  const reportWebVitals = async () => {
    const { onCLS, onFID, onLCP } = await import('web-vitals');
    onCLS(console.log);
    onFID(console.log);
    onLCP(console.log);
  };
  reportWebVitals();
}

// Preload critical assets
const preloadAssets = () => {
  const logo = new Image();
  logo.src = '/oameni-speciali-logo.png';
};

// Execute preloading after initial render
window.addEventListener('load', preloadAssets);

// Enable HMR with proper error recovery
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    if (mod) {
      const { default: NewApp } = mod;
      try {
        root.render(
          <React.StrictMode>
            <NewApp />
          </React.StrictMode>
        );
      } catch (error) {
        console.error('Error during HMR update:', error);
      }
    }
  });
}
