import React from 'react';
import { BarChart, Users, Globe, Clock } from 'lucide-react';

const Analytics: React.FC = () => {
  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold text-white">Analitice</h1>

      {/* Analytics Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white/5 p-6 rounded-lg border border-white/10">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-blue-500/10 rounded-lg">
              <Users className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Vizitatori Unici</p>
              <p className="text-2xl font-bold text-white">1,234</p>
            </div>
          </div>
        </div>

        <div className="bg-white/5 p-6 rounded-lg border border-white/10">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-green-500/10 rounded-lg">
              <BarChart className="h-6 w-6 text-green-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Rata de Conversie</p>
              <p className="text-2xl font-bold text-white">4.2%</p>
            </div>
          </div>
        </div>

        <div className="bg-white/5 p-6 rounded-lg border border-white/10">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-purple-500/10 rounded-lg">
              <Globe className="h-6 w-6 text-purple-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Țări Active</p>
              <p className="text-2xl font-bold text-white">12</p>
            </div>
          </div>
        </div>

        <div className="bg-white/5 p-6 rounded-lg border border-white/10">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-orange-500/10 rounded-lg">
              <Clock className="h-6 w-6 text-orange-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Timp Mediu</p>
              <p className="text-2xl font-bold text-white">2:45</p>
            </div>
          </div>
        </div>
      </div>

      {/* Coming Soon Message */}
      <div className="bg-white/5 p-8 rounded-lg border border-white/10 text-center">
        <h2 className="text-2xl font-semibold text-white mb-4">
          Analitice Avansate în Curând
        </h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Lucrăm la implementarea unor funcționalități avansate de analiză, 
          inclusiv grafice detaliate, rapoarte personalizabile și integrare 
          cu Google Analytics.
        </p>
      </div>
    </div>
  );
};

export default Analytics;
