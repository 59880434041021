import { useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function CountdownTimer() {
  // Calculate end date: Start from Nov 20, 2024 00:00:00 and add 55 days
  const getEndDate = useCallback(() => {
    const storedEndDate = localStorage.getItem('endDate');
    if (storedEndDate) {
      return parseInt(storedEndDate);
    }

    // Set start date to Nov 20, 2024 00:00:00
    const startDate = new Date(2024, 10, 20, 0, 0, 0, 0);
    // Add exactly 55 days
    const endDate = new Date(startDate.getTime() + (55 * 24 * 60 * 60 * 1000));
    const endTimestamp = endDate.getTime();
    localStorage.setItem('endDate', endTimestamp.toString());
    return endTimestamp;
  }, []);

  const calculateTimeLeft = useCallback((): TimeLeft => {
    const now = Date.now();
    const difference = getEndDate() - now;
    
    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
    
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000)
    };
  }, [getEndDate]);

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft);
  const [isHovered, setIsHovered] = useState<string | null>(null);

  useEffect(() => {
    // Initial calculation
    setTimeLeft(calculateTimeLeft());

    // Update every second
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const formatNumber = useCallback((num: number) => num.toString().padStart(2, '0'), []);

  const getUnitLabel = useCallback((unit: keyof TimeLeft, value: number) => {
    const labels: Record<keyof TimeLeft, [string, string]> = {
      days: ['zi', 'zile'],
      hours: ['oră', 'ore'],
      minutes: ['minut', 'minute'],
      seconds: ['secundă', 'secunde']
    };
    
    return labels[unit][value === 1 ? 0 : 1];
  }, []);

  return (
    <div className="flex flex-wrap justify-center gap-4 sm:gap-6 px-2">
      {(Object.entries(timeLeft) as [keyof TimeLeft, number][]).map(([unit, value]) => (
        <motion.div
          key={unit}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col"
        >
          <div
            onMouseEnter={() => setIsHovered(unit)}
            onMouseLeave={() => setIsHovered(null)}
            className={`relative w-24 sm:w-36 h-24 sm:h-36 bg-gradient-to-br from-gray-800/80 to-gray-900/80 backdrop-blur-xl rounded-2xl overflow-hidden group cursor-pointer transition-all duration-300 ${
              isHovered === unit ? 'scale-105' : ''
            }`}
          >
            {/* Background gradient animation */}
            <div className="absolute inset-0 bg-gradient-to-br from-rose-500/10 to-purple-600/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            
            {/* Border glow */}
            <div className="absolute inset-0 border border-white/10 rounded-2xl group-hover:border-rose-500/30 transition-colors duration-300" />
            
            {/* Content */}
            <div className="relative flex flex-col items-center justify-center h-full p-4">
              <AnimatePresence mode="wait">
                <motion.div
                  key={value}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="text-3xl sm:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-2"
                >
                  {formatNumber(value)}
                </motion.div>
              </AnimatePresence>
              
              <div className="text-xs sm:text-sm text-gray-400 capitalize group-hover:text-rose-400 transition-colors duration-300">
                {getUnitLabel(unit, value)}
              </div>

              {/* Decorative dots */}
              <div className="absolute top-2 left-2 w-1 h-1 bg-rose-500/50 rounded-full" />
              <div className="absolute top-2 right-2 w-1 h-1 bg-purple-500/50 rounded-full" />
              <div className="absolute bottom-2 left-2 w-1 h-1 bg-purple-500/50 rounded-full" />
              <div className="absolute bottom-2 right-2 w-1 h-1 bg-rose-500/50 rounded-full" />
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
}
