import React from 'react';
import { motion } from 'framer-motion';
import {
  Users,
  BarChart2,
  Settings,
  Shield,
  Database,
  Bell,
  Mail,
  Flag,
  AlertTriangle,
  Activity,
  LogOut
} from 'lucide-react';

interface SidebarItemProps {
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
  onClick: () => void;
  notifications?: number;
  variant?: 'default' | 'danger';
}

const SidebarItem: React.FC<SidebarItemProps> = ({ 
  icon, 
  label, 
  isActive, 
  onClick, 
  notifications,
  variant = 'default'
}) => (
  <motion.button
    whileHover={{ x: 5 }}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
    className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-all duration-200 ${
      variant === 'danger' 
        ? 'text-red-400 hover:text-red-300 hover:bg-red-500/10' 
        : isActive 
          ? 'bg-gradient-to-r from-blue-500/10 to-indigo-500/10 text-blue-400 border border-blue-500/20' 
          : 'text-gray-400 hover:text-white hover:bg-white/5'
    }`}
    aria-label={`${label}`}
    title={label}
  >
    {icon}
    <span className="font-medium">{label}</span>
    {notifications && (
      <span className="ml-auto bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded-full">
        {notifications}
      </span>
    )}
  </motion.button>
);

interface AdminSidebarProps {
  activeSection: string;
  onSectionChange: (section: string) => void;
  onLogout: () => void;
}

export const AdminSidebar: React.FC<AdminSidebarProps> = ({ 
  activeSection, 
  onSectionChange,
  onLogout 
}) => {
  const menuItems = [
    { id: 'dashboard', icon: <BarChart2 size={20} />, label: 'Panou Principal' },
    { id: 'waitlist', icon: <Users size={20} />, label: 'Listă de Așteptare', notifications: 5 },
    { id: 'analytics', icon: <Activity size={20} />, label: 'Statistici' },
    { id: 'reports', icon: <Flag size={20} />, label: 'Rapoarte' },
    { id: 'notifications', icon: <Bell size={20} />, label: 'Notificări', notifications: 3 },
    { id: 'emails', icon: <Mail size={20} />, label: 'Email în Masă' },
    { id: 'security', icon: <Shield size={20} />, label: 'Jurnal de Securitate' },
    { id: 'database', icon: <Database size={20} />, label: 'Bază de Date' },
    { id: 'settings', icon: <Settings size={20} />, label: 'Setări' },
  ];

  return (
    <div className="w-64 h-screen bg-gradient-to-b from-gray-900 to-gray-800 border-r border-white/10 p-4 fixed left-0 top-0 flex flex-col">
      <div className="flex items-center gap-3 px-4 py-6 border-b border-white/10 mb-6">
        <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-indigo-500 flex items-center justify-center">
          <Shield size={20} className="text-white" />
        </div>
        <span className="text-lg font-bold text-white">Panou Administrare</span>
      </div>

      <div className="space-y-2 flex-grow">
        {menuItems.map((item) => (
          <SidebarItem
            key={item.id}
            icon={item.icon}
            label={item.label}
            isActive={activeSection === item.id}
            onClick={() => onSectionChange(item.id)}
            notifications={item.notifications}
          />
        ))}
      </div>

      {/* System Status */}
      <div className="space-y-4 mt-auto">
        <div className="bg-white/5 rounded-lg p-4 border border-white/10">
          <div className="flex items-center gap-2 mb-2">
            <AlertTriangle size={16} className="text-yellow-500" />
            <span className="text-sm font-medium text-white">Stare Sistem</span>
          </div>
          <div className="flex items-center gap-2 text-xs text-gray-400">
            <div className="w-2 h-2 rounded-full bg-green-500" />
            <span>Toate sistemele funcționează</span>
          </div>
        </div>

        {/* Logout Button */}
        <SidebarItem
          icon={<LogOut size={20} />}
          label="Deconectare"
          isActive={false}
          onClick={onLogout}
          variant="danger"
        />
      </div>
    </div>
  );
};

export default AdminSidebar;
