import React from 'react';
import { motion } from 'framer-motion';

export const HeroHeader: React.FC = () => {
  return (
    <>
      {/* Launch Soon Section */}
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="relative inline-block mb-6 sm:mb-8"
      >
        <motion.div
          whileHover={{ scale: 1.05 }}
          className="relative px-8 py-4 bg-white/5 backdrop-blur-xl rounded-xl border border-white/10 shadow-lg transition-all duration-300 group overflow-hidden"
        >
          {/* Subtle light beam effect */}
          <motion.div
            className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent"
            animate={{
              x: ['-100%', '100%'],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
          
          {/* Content */}
          <div className="relative flex items-center justify-center gap-3">
            <motion.span
              animate={{
                y: [-2, 2, -2],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="text-2xl"
            >
              🚀
            </motion.span>
            <span className="text-white font-bold text-xl tracking-wide group-hover:text-blue-400 transition-colors">
              Lansare în curând
            </span>
            <motion.span
              animate={{
                scale: [1, 1.2, 1],
                rotate: [0, 10, -10, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse"
              }}
              className="text-2xl"
            >
              ✨
            </motion.span>
          </div>

          {/* Bottom border glow */}
          <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-transparent via-white/30 to-transparent" />
        </motion.div>
      </motion.div>

      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.8 }}
        className="text-3xl sm:text-6xl font-bold text-white mb-6 sm:mb-8 leading-tight px-4"
      >
        Construim platforma perfectă pentru
        <span className="block bg-gradient-to-r from-blue-400 to-indigo-500 bg-clip-text text-transparent animate-pulse-slow mt-2">
          networking în domeniul sănătății mintale
        </span>
      </motion.h1>

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6 }}
        className="text-lg sm:text-xl text-gray-300 mb-8 sm:mb-12 max-w-2xl mx-auto leading-relaxed px-4"
      >
        Conectăm profesioniști și persoane care au nevoie de sprijin într-un mediu sigur și de încredere.
      </motion.p>
    </>
  );
};
