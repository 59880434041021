import React from 'react';
import { Download, RefreshCw } from 'lucide-react';

interface DatabaseStatus {
  status: string;
  details: {
    version?: string;
    timestamp?: string;
    tables?: Array<{
      table_name: string;
      row_count: number;
    }>;
    settings?: Array<{
      key: string;
      value: string;
    }>;
    error?: {
      code?: string;
      message: string;
      details?: string;
    };
  };
}

interface DatabaseActionsProps {
  dbStatus: DatabaseStatus;
  onBackup: () => void;
  onReset: () => void;
}

export const DatabaseActions: React.FC<DatabaseActionsProps> = ({
  dbStatus,
  onBackup,
  onReset,
}) => {
  const isConnected = dbStatus.status === 'connected';

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div className="w-full sm:w-auto">
          <h1 className="text-2xl sm:text-3xl font-bold text-white mb-4">Panou de Control</h1>
          <div className="flex flex-wrap items-center gap-4">
            <div className="flex items-center">
              <div className={`w-3 h-3 rounded-full mr-2 ${isConnected ? 'bg-green-500' : 'bg-red-500'}`} />
              <span className="text-sm text-gray-400">
                Stare: {isConnected ? 'Conectat' : 'Eroare'}
              </span>
            </div>
            <button
              onClick={onBackup}
              className="text-sm text-blue-400 hover:text-blue-300 flex items-center gap-2"
              aria-label="Backup bază de date"
            >
              <Download size={16} />
              <span>Backup</span>
            </button>
            <button
              onClick={onReset}
              className="text-sm text-red-400 hover:text-red-300 flex items-center gap-2"
              aria-label="Resetare bază de date"
            >
              <RefreshCw size={16} />
              <span>Resetare</span>
            </button>
          </div>
        </div>
        <button
          onClick={() => {
            localStorage.removeItem('adminToken');
            window.location.reload();
          }}
          className="w-full sm:w-auto px-4 py-2 bg-red-500/10 text-red-400 rounded-lg hover:bg-red-500/20 transition-all duration-200"
          aria-label="Deconectare din panoul de administrare"
        >
          Deconectare
        </button>
      </div>

      {dbStatus.details.error && (
        <div className="bg-red-500/10 p-4 rounded-lg border border-red-500/20">
          <h3 className="text-red-400 font-semibold mb-2">Eroare de Conexiune:</h3>
          <p className="text-sm text-red-300">{dbStatus.details.error.message}</p>
          {dbStatus.details.error.details && (
            <p className="text-xs text-red-400 mt-1">{dbStatus.details.error.details}</p>
          )}
          {dbStatus.details.error.code && (
            <p className="text-xs text-gray-400 mt-2">
              Cod eroare: {dbStatus.details.error.code}
            </p>
          )}
        </div>
      )}
    </div>
  );
};
