import React, { useState, useEffect } from 'react';
import { Activity, Users, Clock, MapPin, RefreshCw } from 'lucide-react';

interface DashboardStats {
  totalRegistrations: number;
  remainingSpots: number;
  activeUsers: number;
  averageResponseTime: string;
  geographicDistribution: {
    [key: string]: number;
  };
  recentActivity: Array<{
    id: string;
    type: string;
    details: string;
    timestamp: string;
  }>;
}

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const fetchDashboardStats = async () => {
    if (!localStorage.getItem('authToken')) return;

    try {
      setIsRefreshing(true);
      const response = await fetch('/api/dashboard/overview', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch dashboard stats');
      }

      const data = await response.json();
      setStats({
        totalRegistrations: data.waitlist.total || 0,
        remainingSpots: data.waitlist.remaining || 0,
        activeUsers: data.visitors?.total || 0,
        averageResponseTime: '< 1s',
        geographicDistribution: data.visitors?.geographicDistribution || {},
        recentActivity: data.visitors?.recentActivity || []
      });
      setLastUpdated(new Date());
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchDashboardStats();

    // Minimal ping every 10 minutes to keep database active
    const pingInterval = setInterval(async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) return;

        await fetch('/api/health', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          },
          credentials: 'include'
        });
      } catch (error) {
        console.error('Health check failed:', error);
      }
    }, 10 * 60 * 1000); // 10 minutes

    return () => clearInterval(pingInterval);
  }, []);

  if (loading && !stats) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-white">Dashboard</h1>
        <div className="flex items-center gap-4">
          {lastUpdated && (
            <span className="text-sm text-gray-400">
              Ultima actualizare: {lastUpdated.toLocaleTimeString('ro-RO')}
            </span>
          )}
          <button
            onClick={fetchDashboardStats}
            disabled={isRefreshing}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 transition-colors ${
              isRefreshing ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <RefreshCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
            <span>{isRefreshing ? 'Se actualizează...' : 'Actualizează'}</span>
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4 text-red-400">
          {error}
        </div>
      )}

      {stats && (
        <>
          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-white/5 p-6 rounded-lg border border-white/10">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-blue-500/10 rounded-lg">
                  <Users className="h-6 w-6 text-blue-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Total Înregistrări</p>
                  <p className="text-2xl font-bold text-white">{stats.totalRegistrations}</p>
                </div>
              </div>
            </div>

            <div className="bg-white/5 p-6 rounded-lg border border-white/10">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-green-500/10 rounded-lg">
                  <Activity className="h-6 w-6 text-green-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Locuri Disponibile</p>
                  <p className="text-2xl font-bold text-white">{stats.remainingSpots}</p>
                </div>
              </div>
            </div>

            <div className="bg-white/5 p-6 rounded-lg border border-white/10">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-purple-500/10 rounded-lg">
                  <Clock className="h-6 w-6 text-purple-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Timp de Răspuns</p>
                  <p className="text-2xl font-bold text-white">{stats.averageResponseTime}</p>
                </div>
              </div>
            </div>

            <div className="bg-white/5 p-6 rounded-lg border border-white/10">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-orange-500/10 rounded-lg">
                  <MapPin className="h-6 w-6 text-orange-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Orașe Active</p>
                  <p className="text-2xl font-bold text-white">
                    {Object.keys(stats.geographicDistribution).length}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Geographic Distribution */}
          <div className="bg-white/5 p-6 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold text-white mb-4">Distribuție Geografică</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {Object.entries(stats.geographicDistribution)
                .sort(([, a], [, b]) => b - a)
                .map(([city, count]) => (
                  <div key={city} className="flex justify-between items-center p-3 bg-white/5 rounded-lg">
                    <span className="text-gray-300">{city}</span>
                    <span className="text-blue-400 font-semibold">{count}</span>
                  </div>
                ))}
            </div>
          </div>

          {/* Recent Activity */}
          <div className="bg-white/5 p-6 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold text-white mb-4">Activitate Recentă</h2>
            <div className="space-y-4">
              {stats.recentActivity.map((activity) => (
                <div key={activity.id} className="flex items-center justify-between p-3 bg-white/5 rounded-lg">
                  <div>
                    <p className="text-gray-300">{activity.details}</p>
                    <p className="text-sm text-gray-500">{activity.type}</p>
                  </div>
                  <span className="text-sm text-gray-400">
                    {new Date(activity.timestamp).toLocaleString('ro-RO')}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
