import React, { useState } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const LegalModal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4 sm:p-6">
      <div className="bg-gradient-to-b from-gray-900 to-gray-800 rounded-xl border border-white/10 p-6 sm:p-8 max-w-4xl max-h-[90vh] overflow-y-auto relative">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors"
        >
          ✕
        </button>
        <h2 className="text-2xl font-bold text-white mb-6">{title}</h2>
        <div className="prose prose-invert max-w-none">
          {children}
        </div>
      </div>
    </div>
  );
};

export function Footer() {
  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [gdprOpen, setGdprOpen] = useState(false);

  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative mt-20 border-t border-white/10 bg-gradient-to-b from-gray-900 to-black">
      <div className="mx-auto max-w-7xl px-4 py-8">
        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="flex flex-wrap justify-center gap-4 text-sm text-gray-400">
            <button
              onClick={() => setTermsOpen(true)}
              className="hover:text-rose-500 transition-colors"
            >
              Termeni și Condiții
            </button>
            <span className="text-gray-600">•</span>
            <button
              onClick={() => setPrivacyOpen(true)}
              className="hover:text-rose-500 transition-colors"
            >
              Politica de Confidențialitate
            </button>
            <span className="text-gray-600">•</span>
            <button
              onClick={() => setGdprOpen(true)}
              className="hover:text-rose-500 transition-colors"
            >
              GDPR
            </button>
          </div>
          
          <div className="text-sm text-gray-500">
            © {currentYear} Oameni Speciali. Toate drepturile rezervate.
          </div>
        </div>
      </div>

      {/* Terms Modal */}
      <LegalModal
        isOpen={termsOpen}
        onClose={() => setTermsOpen(false)}
        title="Termeni și Condiții"
      >
        <div className="space-y-6 text-gray-300">
          <h3 className="text-xl font-semibold text-white">1. Introducere</h3>
          <p>
            Bun venit pe platforma Oameni Speciali. Acești termeni și condiții guvernează utilizarea platformei noastre de networking în domeniul sănătății mintale.
          </p>

          <h3 className="text-xl font-semibold text-white">2. Eligibilitate</h3>
          <p>
            Pentru a utiliza platforma, trebuie să fiți:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Un profesionist licențiat în domeniul sănătății mintale sau</li>
            <li>O persoană care caută sprijin în domeniul sănătății mintale</li>
            <li>Să aveți cel puțin 18 ani</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">3. Serviciile Noastre</h3>
          <p>
            Platforma oferă:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Networking între profesioniști în sănătatea mintală</li>
            <li>Resurse educaționale și profesionale</li>
            <li>Oportunități de colaborare</li>
            <li>Spațiu sigur pentru discuții și suport</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">4. Responsabilități Profesionale</h3>
          <p>
            Profesioniștii care utilizează platforma trebuie să:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Mențină standardele profesionale și etice</li>
            <li>Respecte confidențialitatea pacienților</li>
            <li>Furnizeze informații precise despre calificările lor</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">5. Confidențialitate și Securitate</h3>
          <p>
            Ne angajăm să:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Protejăm datele personale ale utilizatorilor</li>
            <li>Menținem securitatea platformei</li>
            <li>Respectăm toate legile aplicabile privind protecția datelor</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">6. Conduită</h3>
          <p>
            Utilizatorii trebuie să:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Respecte alți utilizatori</li>
            <li>Nu distribuie conținut dăunător sau ilegal</li>
            <li>Raporteze orice comportament inadecvat</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">7. Proprietate Intelectuală</h3>
          <p>
            Tot conținutul platformei este proprietatea Oameni Speciali sau a licențiatorilor săi.
          </p>

          <h3 className="text-xl font-semibold text-white">8. Modificări</h3>
          <p>
            Ne rezervăm dreptul de a modifica acești termeni, cu notificare prealabilă.
          </p>
        </div>
      </LegalModal>

      {/* Privacy Modal */}
      <LegalModal
        isOpen={privacyOpen}
        onClose={() => setPrivacyOpen(false)}
        title="Politica de Confidențialitate"
      >
        <div className="space-y-6 text-gray-300">
          <h3 className="text-xl font-semibold text-white">1. Colectarea Datelor</h3>
          <p>
            Colectăm următoarele tipuri de date:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Informații de profil profesional</li>
            <li>Date de contact</li>
            <li>Informații despre utilizarea platformei</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">2. Utilizarea Datelor</h3>
          <p>
            Utilizăm datele pentru:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Furnizarea serviciilor platformei</li>
            <li>Îmbunătățirea experienței utilizatorilor</li>
            <li>Asigurarea securității</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">3. Protecția Datelor</h3>
          <p>
            Implementăm măsuri de securitate pentru:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Prevenirea accesului neautorizat</li>
            <li>Protejarea informațiilor sensibile</li>
            <li>Asigurarea confidențialității</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">4. Drepturile Utilizatorilor</h3>
          <p>
            Aveți dreptul să:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Accesați datele personale</li>
            <li>Corectați informațiile inexacte</li>
            <li>Solicitați ștergerea datelor</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">5. Cookie-uri</h3>
          <p>
            Utilizăm cookie-uri pentru:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Îmbunătățirea funcționalității</li>
            <li>Analizarea utilizării platformei</li>
            <li>Personalizarea experienței</li>
          </ul>
        </div>
      </LegalModal>

      {/* GDPR Modal */}
      <LegalModal
        isOpen={gdprOpen}
        onClose={() => setGdprOpen(false)}
        title="Conformitate GDPR"
      >
        <div className="space-y-6 text-gray-300">
          <h3 className="text-xl font-semibold text-white">1. Baza Legală</h3>
          <p>
            Procesăm datele în conformitate cu:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Regulamentul General privind Protecția Datelor (GDPR)</li>
            <li>Legislația română privind protecția datelor</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">2. Drepturile GDPR</h3>
          <p>
            Conform GDPR, aveți dreptul la:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Acces la date</li>
            <li>Rectificare</li>
            <li>Ștergere ("dreptul de a fi uitat")</li>
            <li>Restricționarea procesării</li>
            <li>Portabilitatea datelor</li>
            <li>Opoziție</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">3. Transfer Date</h3>
          <p>
            Pentru transferurile de date:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Utilizăm clauze contractuale standard</li>
            <li>Implementăm măsuri de securitate adecvate</li>
            <li>Obținem consimțământul explicit când este necesar</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">4. Măsuri Tehnice</h3>
          <p>
            Implementăm:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Criptare end-to-end</li>
            <li>Pseudonimizare</li>
            <li>Backup-uri regulate</li>
            <li>Controale de acces stricte</li>
          </ul>

          <h3 className="text-xl font-semibold text-white">5. Responsabil Protecția Datelor</h3>
          <p>
            Avem un DPO dedicat pentru:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Monitorizarea conformității</li>
            <li>Gestionarea solicitărilor privind datele</li>
            <li>Actualizarea politicilor de protecție a datelor</li>
          </ul>
        </div>
      </LegalModal>
    </footer>
  );
}
