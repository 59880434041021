import React from 'react';
import { motion } from 'framer-motion';
import { Star, Users } from 'lucide-react';

const categories = [
  { 
    name: 'Depresie',
    delay: 0.1,
    stats: '1 din 4 români',
    urgency: 'Foarte căutat',
    icon: '😔'
  },
  { 
    name: 'Anxietate',
    delay: 0.2,
    stats: '40% din populație',
    urgency: 'Cerere ridicată',
    icon: '😰'
  },
  { 
    name: 'OCD',
    delay: 0.3,
    stats: '2.3% prevalență',
    urgency: 'Specializare rară',
    icon: '🔄'
  },
  { 
    name: 'Bipolar',
    delay: 0.4,
    stats: '2.6% din adulți',
    urgency: 'Experți limitați',
    icon: '🔄'
  },
  { 
    name: 'Narcisism',
    delay: 0.5,
    stats: '5% prevalență',
    urgency: 'În creștere',
    icon: '🎭'
  },
  { 
    name: 'ADHD',
    delay: 0.6,
    stats: '5-10% copii',
    urgency: 'Mare căutare',
    icon: '⚡'
  },
  { 
    name: 'Tourette',
    delay: 0.7,
    stats: '1% prevalență',
    urgency: 'Puțini specialiști',
    icon: '🔄'
  },
  { 
    name: 'Dependențe',
    delay: 0.8,
    stats: '15% populație',
    urgency: 'Urgent',
    icon: '⛓️'
  }
];

export const HeroCategories: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4">
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-4">
        {categories.map((category) => (
          <motion.div
            key={category.name}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: category.delay }}
            whileHover={{ scale: 1.05, y: -5 }}
            className="relative overflow-hidden bg-gradient-to-br from-white/5 to-white/10 backdrop-blur-sm border border-white/10 rounded-lg p-4 hover:border-blue-500/50 transition-all duration-300 group"
          >
            {/* Animated gradient background */}
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 via-blue-500/10 to-blue-500/0 animate-shimmer" />
            
            {/* Glowing border effect */}
            <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-blue-500/20 to-indigo-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 blur" />
            
            <div className="relative z-10">
              <div className="flex items-center justify-between mb-2">
                <span className="text-2xl">{category.icon}</span>
                <span className="text-xs text-blue-400 font-semibold px-2 py-1 bg-blue-500/10 rounded-full">
                  {category.urgency}
                </span>
              </div>
              
              <h3 className="text-white font-semibold mb-2 group-hover:text-blue-400 transition-colors">
                {category.name}
              </h3>
              
              <div className="text-xs text-gray-400 group-hover:text-white transition-colors">
                {category.stats}
              </div>
              
              <div className="absolute bottom-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                <Star className="w-4 h-4 text-blue-400" />
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      
      {/* LGBTQ+ Friendly - Full Width */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9 }}
        whileHover={{ scale: 1.02 }}
        className="relative overflow-hidden bg-gradient-to-r from-blue-500/10 to-indigo-500/10 backdrop-blur-sm border border-white/10 rounded-lg p-6 hover:border-blue-500/50 transition-all duration-300 group"
      >
        {/* Rainbow gradient animation */}
        <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 via-yellow-500/20 via-green-500/20 via-blue-500/20 to-purple-500/20 animate-gradient bg-[length:200%_200%]" />
        
        <div className="relative z-10 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <span className="text-2xl">🌈</span>
            <div>
              <h3 className="text-white font-semibold text-lg group-hover:text-blue-400 transition-colors">
                LGBTQ+ Friendly
              </h3>
              <p className="text-sm text-gray-400 group-hover:text-white transition-colors">
                Comunitate incluzivă și sigură pentru toți
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Users className="w-5 h-5 text-blue-400" />
            <span className="text-white font-medium">Spațiu Sigur</span>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
