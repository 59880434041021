import React from 'react';
import { Edit2, Trash2 } from 'lucide-react';

export interface WaitlistEntry {
  id: number;
  full_name: string;
  email: string;
  phone: string;
  country: string;
  city: string;
  created_at: string;
}

interface WaitlistTableProps {
  entries: WaitlistEntry[];
  onEdit: (entry: WaitlistEntry) => void;
  onDelete: (id: number) => void;
}

export const WaitlistTable: React.FC<WaitlistTableProps> = ({
  entries,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="mt-8">
      {/* Desktop Table */}
      <div className="hidden md:block overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b border-gray-700">
              <th className="pb-3 text-sm font-medium text-gray-400">Nume</th>
              <th className="pb-3 text-sm font-medium text-gray-400">Email</th>
              <th className="pb-3 text-sm font-medium text-gray-400">Telefon</th>
              <th className="pb-3 text-sm font-medium text-gray-400">Oraș</th>
              <th className="pb-3 text-sm font-medium text-gray-400">Țară</th>
              <th className="pb-3 text-sm font-medium text-gray-400">Data</th>
              <th className="pb-3 text-sm font-medium text-gray-400">Acțiuni</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry) => (
              <tr 
                key={entry.id}
                className="border-b border-gray-800 hover:bg-gray-800/30 transition-colors"
              >
                <td className="py-4 text-white">{entry.full_name}</td>
                <td className="py-4 text-gray-300">{entry.email}</td>
                <td className="py-4 text-gray-300">{entry.phone}</td>
                <td className="py-4 text-gray-300">{entry.city}</td>
                <td className="py-4 text-gray-300">{entry.country}</td>
                <td className="py-4 text-gray-300">
                  {new Date(entry.created_at).toLocaleString('ro-RO')}
                </td>
                <td className="py-4">
                  <div className="flex items-center gap-3">
                    <button
                      onClick={() => onEdit(entry)}
                      className="p-1 text-blue-400 hover:text-blue-300 transition-colors"
                      aria-label="Editează înregistrarea"
                    >
                      <Edit2 size={16} />
                    </button>
                    <button
                      onClick={() => onDelete(entry.id)}
                      className="p-1 text-red-400 hover:text-red-300 transition-colors"
                      aria-label="Șterge înregistrarea"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile Cards */}
      <div className="md:hidden space-y-4">
        {entries.map((entry) => (
          <div 
            key={entry.id}
            className="bg-gray-800/30 rounded-lg p-4 space-y-3"
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-white font-medium">{entry.full_name}</h3>
                <p className="text-sm text-gray-400">{entry.email}</p>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => onEdit(entry)}
                  className="p-1.5 bg-blue-500/10 text-blue-400 rounded-lg hover:bg-blue-500/20 transition-colors"
                  aria-label="Editează înregistrarea"
                >
                  <Edit2 size={14} />
                </button>
                <button
                  onClick={() => onDelete(entry.id)}
                  className="p-1.5 bg-red-500/10 text-red-400 rounded-lg hover:bg-red-500/20 transition-colors"
                  aria-label="Șterge înregistrarea"
                >
                  <Trash2 size={14} />
                </button>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2 text-sm">
              <div>
                <p className="text-gray-400">Telefon</p>
                <p className="text-gray-300">{entry.phone}</p>
              </div>
              <div>
                <p className="text-gray-400">Locație</p>
                <p className="text-gray-300">{entry.city}, {entry.country}</p>
              </div>
            </div>

            <div className="text-xs text-gray-500">
              {new Date(entry.created_at).toLocaleString('ro-RO')}
            </div>
          </div>
        ))}
      </div>
      
      {entries.length === 0 && (
        <div className="text-center py-8 text-gray-400">
          Nu există înregistrări în lista de așteptare.
        </div>
      )}
    </div>
  );
};
