import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const platformUpdateTemplate = `
<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
  <img src="/oameni-speciali-logo.png" alt="Oameni Speciali Logo" style="max-width: 100px; margin: 20px auto; display: block;" />
  <h2>Avem vești importante pentru tine!</h2>
  <p>Platforma Oameni Speciali evoluează constant și dorim să îți împărtășim cele mai recente actualizări.</p>
  <div style="margin: 30px 0; padding: 20px; background: #f8f9fa; border-radius: 8px;">
    <h3 style="color: #e91e63;">Ultimele Noutăți</h3>
    <ul>
      <li>Noi funcționalități în dezvoltare</li>
      <li>Îmbunătățiri ale experienței utilizatorilor</li>
      <li>Data estimativă de lansare</li>
    </ul>
  </div>
  <p>Rămâi aproape pentru mai multe actualizări!</p>
  <p>Cu stimă,<br>Echipa Oameni Speciali</p>
</div>
`;

interface SendingStatus {
  email: string;
  status: 'pending' | 'sending' | 'success' | 'error';
  messageId?: string;
  error?: string;
}

const EmailSection: React.FC = () => {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState(platformUpdateTemplate);
  const [users, setUsers] = useState<Array<{ id: string; email: string; name: string; selected: boolean }>>([]);
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [sendingStatuses, setSendingStatuses] = useState<SendingStatus[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAuthError = () => {
    localStorage.removeItem('authToken');
    navigate('/admin');
  };

  const getAuthHeaders = () => {
    const token = localStorage.getItem('authToken');
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : ''
    };
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch('/api/admin/email-recipients', {
        credentials: 'include',
        headers: getAuthHeaders()
      });

      if (response.status === 401 || response.status === 403) {
        handleAuthError();
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (data && Array.isArray(data.users)) {
        setUsers(data.users.map((user: any) => ({
          id: user.id || '',
          email: user.email || '',
          name: user.name || 'Unknown',
          selected: false
        })));
      } else {
        throw new Error('Invalid data format received from server');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error instanceof Error ? error.message : 'A apărut o eroare la încărcarea destinatarilor');
      setUsers([]); // Set empty array on error
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadTemplate = () => {
    setContent(platformUpdateTemplate);
    setSubject('Actualizare Platformă Oameni Speciali');
  };

  const handleSelectAllUsers = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setUsers(users.map(user => ({ ...user, selected: newSelectAll })));
  };

  const handleUserSelect = (userId: string) => {
    setUsers(users.map(user => {
      if (user.id === userId) {
        return { ...user, selected: !user.selected };
      }
      return user;
    }));
    // Update selectAll state based on all users being selected
    setSelectAll(users.every(user => user.selected));
  };

  const handleSendEmail = async () => {
    setIsSending(true);
    setStatus(null);
    setError(null);

    try {
      const selectedUsers = users.filter(user => user.selected);
      
      if (selectedUsers.length === 0) {
        throw new Error('Selectează cel puțin un destinatar');
      }

      if (!subject.trim()) {
        throw new Error('Te rugăm să introduci subiectul emailului');
      }

      if (!content.trim()) {
        throw new Error('Te rugăm să introduci conținutul emailului');
      }

      // Initialize sending statuses
      setSendingStatuses(selectedUsers.map(user => ({
        email: user.email,
        status: 'pending'
      })));

      // Send emails one by one
      let successCount = 0;
      for (const user of selectedUsers) {
        // Update status to sending
        setSendingStatuses(prev => prev.map(status => 
          status.email === user.email 
            ? { ...status, status: 'sending' }
            : status
        ));

        try {
          const response = await fetch('/api/admin/send-mass-email', {
            method: 'POST',
            headers: getAuthHeaders(),
            credentials: 'include',
            body: JSON.stringify({
              subject,
              content,
              recipients: [user.email]
            }),
          });

          if (response.status === 401 || response.status === 403) {
            handleAuthError();
            return;
          }

          const data = await response.json();

          if (!response.ok) {
            throw new Error(data.error || 'A apărut o eroare la trimiterea emailului');
          }

          // Update status to success
          setSendingStatuses(prev => prev.map(status => 
            status.email === user.email 
              ? { ...status, status: 'success', messageId: data.messageId }
              : status
          ));

          successCount++;
        } catch (error) {
          // Update status to error
          setSendingStatuses(prev => prev.map(status => 
            status.email === user.email 
              ? { ...status, status: 'error', error: error instanceof Error ? error.message : 'Eroare necunoscută' }
              : status
          ));
        }
      }

      if (successCount === selectedUsers.length) {
        setStatus({
          type: 'success',
          message: `Toate emailurile au fost trimise cu succes (${successCount} din ${selectedUsers.length})`
        });
        // Reset form after successful send
        setSubject('');
        setContent(platformUpdateTemplate);
        setUsers(users.map(user => ({ ...user, selected: false })));
        setSelectAll(false);
      } else if (successCount > 0) {
        setStatus({
          type: 'success',
          message: `${successCount} din ${selectedUsers.length} emailuri au fost trimise cu succes`
        });
      } else {
        throw new Error('Nu s-a putut trimite niciun email');
      }
    } catch (error) {
      setStatus({
        type: 'error',
        message: error instanceof Error ? error.message : 'A apărut o eroare la trimiterea emailurilor'
      });
    } finally {
      setIsSending(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-white">Se încarcă...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-red-400">{error}</div>
      </div>
    );
  }

  return (
    <div className="space-y-6 max-w-7xl mx-auto">
      <div className="bg-gray-800/50 border border-white/10 rounded-xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">Email în Masă</h2>
          <div className="space-x-4">
            <button
              onClick={handleLoadTemplate}
              className="px-4 py-2 bg-blue-500/10 text-blue-400 rounded-lg hover:bg-blue-500/20 transition-colors"
            >
              Încarcă Template Actualizare
            </button>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-6">
          {/* Left Column - Email Composer */}
          <div className="col-span-3 space-y-4">
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-300 mb-1">
                Subiect Email
              </label>
              <input
                type="text"
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent text-white"
                placeholder="Introdu subiectul emailului"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              {/* Code Editor */}
              <div className="h-[600px] bg-[#1e1e1e] rounded-lg overflow-hidden">
                <textarea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  className="w-full h-full px-4 py-2 bg-[#1e1e1e] text-white font-mono text-sm resize-none focus:outline-none"
                  placeholder="<div>Introdu codul HTML aici...</div>"
                  spellCheck={false}
                  style={{
                    lineHeight: '1.5',
                    tabSize: 2
                  }}
                />
              </div>

              {/* Live Preview */}
              <div className="h-[600px] bg-white rounded-lg overflow-auto">
                <div
                  className="p-4 text-black"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </div>
          </div>

          {/* Right Column - Recipients */}
          <div>
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-medium text-gray-300">Destinatari</h3>
              <button
                onClick={handleSelectAllUsers}
                className="text-sm text-rose-400 hover:text-rose-300"
              >
                {selectAll ? 'Deselectează Tot' : 'Selectează Tot'}
              </button>
            </div>
            <div className="max-h-[670px] overflow-y-auto space-y-2 bg-white/5 rounded-lg p-4">
              {users.length > 0 ? (
                users.map(user => (
                  <div
                    key={user.id}
                    className="flex items-center space-x-3 p-2 hover:bg-white/5 rounded-lg cursor-pointer"
                    onClick={() => handleUserSelect(user.id)}
                  >
                    <label className="flex items-center space-x-3 cursor-pointer w-full">
                      <input
                        type="checkbox"
                        checked={user.selected}
                        onChange={() => handleUserSelect(user.id)}
                        className="rounded border-gray-400 text-rose-500 focus:ring-rose-500"
                        title={`Select ${user.name}`}
                        aria-label={`Select ${user.name}`}
                      />
                      <div className="flex-1">
                        <div className="text-white">{user.name}</div>
                        <div className="text-gray-400 text-sm">{user.email}</div>
                        {sendingStatuses.find(status => status.email === user.email) && (
                          <div className={`text-sm mt-1 ${
                            sendingStatuses.find(status => status.email === user.email)?.status === 'success'
                              ? 'text-green-400'
                              : sendingStatuses.find(status => status.email === user.email)?.status === 'error'
                              ? 'text-red-400'
                              : sendingStatuses.find(status => status.email === user.email)?.status === 'sending'
                              ? 'text-yellow-400'
                              : 'text-gray-400'
                          }`}>
                            {sendingStatuses.find(status => status.email === user.email)?.status === 'success'
                              ? '✓ Email trimis'
                              : sendingStatuses.find(status => status.email === user.email)?.status === 'error'
                              ? `✗ ${sendingStatuses.find(status => status.email === user.email)?.error}`
                              : sendingStatuses.find(status => status.email === user.email)?.status === 'sending'
                              ? '⟳ Se trimite...'
                              : '• În așteptare'}
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                ))
              ) : (
                <div className="text-gray-400 text-center py-4">
                  Nu există destinatari disponibili
                </div>
              )}
            </div>
          </div>
        </div>

        {status && (
          <div className={`mt-6 p-4 rounded-lg ${
            status.type === 'success' 
              ? 'bg-green-500/10 border border-green-500/20 text-green-400' 
              : 'bg-red-500/10 border border-red-500/20 text-red-400'
          }`}>
            {status.message}
          </div>
        )}

        <button
          onClick={handleSendEmail}
          disabled={isSending || users.length === 0}
          className="w-full mt-6 px-6 py-3 bg-gradient-to-r from-rose-500 to-rose-600 text-white rounded-lg transition-all hover:shadow-lg hover:shadow-rose-500/20 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSending ? 'Se trimite...' : 'Trimite Email'}
        </button>
      </div>
    </div>
  );
};

export default EmailSection;
