import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import { AdminPanelContent } from './AdminPanelContent';
import type { WaitlistEntry, EditFormData } from './EditModal';

interface WaitlistData {
  entries: WaitlistEntry[];
  remainingSpots: number;
  totalRegistrations: number;
  dbStatus: {
    status: string;
    details: any;
  };
}

const AdminPanel: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [waitlistData, setWaitlistData] = useState<WaitlistData | null>(null);
  const [error, setError] = useState('');
  const [editingEntry, setEditingEntry] = useState<WaitlistEntry | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsLoggedIn(true);
      fetchWaitlistData(token);
    }
  }, []);

  // Close sidebar on escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  // Lock body scroll when sidebar is open on mobile
  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isSidebarOpen]);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsLoggedIn(false);
    navigate('/admin');
  };

  const getAuthHeaders = (token: string) => ({
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  });

  const fetchWaitlistData = async (token: string) => {
    try {
      setIsRefreshing(true);
      const response = await fetch('/api/admin/waitlist', {
        headers: getAuthHeaders(token),
        credentials: 'include'
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem('authToken');
          setIsLoggedIn(false);
          navigate('/admin');
          return;
        }
        throw new Error('Eroare la încărcarea datelor');
      }

      const data = await response.json();
      setWaitlistData(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'A apărut o eroare');
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleRefreshWaitlist = () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      fetchWaitlistData(token);
    }
  };

  const handleDelete = async (id: number) => {
    if (!window.confirm('Sigur doriți să ștergeți această înregistrare?')) return;

    const token = localStorage.getItem('authToken');
    if (!token) return;

    try {
      const response = await fetch(`/api/admin/waitlist/${id}`, {
        method: 'DELETE',
        headers: getAuthHeaders(token),
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Eroare la ștergerea înregistrării');
      await fetchWaitlistData(token);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'A apărut o eroare');
    }
  };

  const handleSaveEdit = async (updatedEntry: EditFormData) => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    try {
      const response = await fetch(`/api/admin/waitlist/${updatedEntry.id}`, {
        method: 'PUT',
        headers: getAuthHeaders(token),
        credentials: 'include',
        body: JSON.stringify({
          full_name: updatedEntry.full_name,
          phone: updatedEntry.phone,
          email: updatedEntry.email,
          country: updatedEntry.country,
          city: updatedEntry.city
        }),
      });

      if (!response.ok) throw new Error('Eroare la actualizarea înregistrării');
      setIsEditModalOpen(false);
      await fetchWaitlistData(token);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'A apărut o eroare');
    }
  };

  const handleDatabaseReset = async () => {
    if (!window.confirm('Sigur doriți să resetați baza de date? Această acțiune nu poate fi anulată!')) return;

    const token = localStorage.getItem('authToken');
    if (!token) return;

    try {
      const response = await fetch('/api/admin/database/reset', {
        method: 'POST',
        headers: getAuthHeaders(token),
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Eroare la resetarea bazei de date');
      await fetchWaitlistData(token);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'A apărut o eroare');
    }
  };

  const handleDatabaseBackup = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    try {
      const response = await fetch('/api/admin/database/backup', {
        method: 'POST',
        headers: getAuthHeaders(token),
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Eroare la crearea backup-ului');
      const data = await response.json();
      
      const blob = new Blob([JSON.stringify(data.backup, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `backup-lista-asteptare-${new Date().toISOString()}.json`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'A apărut o eroare');
    }
  };

  if (!isLoggedIn) {
    return <LoginForm onLoginSuccess={(token: string) => {
      setIsLoggedIn(true);
      fetchWaitlistData(token);
    }} />;
  }

  return (
    <AdminPanelContent
      waitlistData={waitlistData}
      error={error}
      editingEntry={editingEntry}
      isEditModalOpen={isEditModalOpen}
      activeSection={activeSection}
      isSidebarOpen={isSidebarOpen}
      onSidebarToggle={() => setIsSidebarOpen(!isSidebarOpen)}
      onSectionChange={setActiveSection}
      onLogout={handleLogout}
      onEdit={(entry) => {
        setEditingEntry(entry);
        setIsEditModalOpen(true);
      }}
      onDelete={handleDelete}
      onEditModalClose={() => {
        setIsEditModalOpen(false);
        setEditingEntry(null);
      }}
      onEditModalSave={handleSaveEdit}
      onBackup={handleDatabaseBackup}
      onReset={handleDatabaseReset}
      onRefreshWaitlist={handleRefreshWaitlist}
      isRefreshing={isRefreshing}
    />
  );
};

export default AdminPanel;
