export function CTASection() {
  return (
    <div className="bg-gradient-to-r from-rose-500/20 to-purple-600/20 backdrop-blur-lg rounded-lg p-8 md:p-12 text-center">
      <h2 className="text-2xl md:text-4xl font-bold mb-4">
        Alătură-te Comunității Oameni Speciali
      </h2>
      <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
        Fii parte din cea mai mare comunitate de profesioniști în sănătate mintală din România
      </p>
    </div>
  );
}
