import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, DollarSign, Coins } from 'lucide-react';
import { RegistrationModal } from './RegistrationModal';

const floatingCoins = Array(5).fill(null);

function CoinAnimation({ index }: { index: number }) {
  return (
    <motion.div
      initial={{ 
        scale: 0.5,
        x: 0,
        y: 0
      }}
      animate={{ 
        scale: [0.5, 1, 1, 0.5],
        x: [-20 + (index * 10), -30 + (index * 15)],
        y: [0, -50 - (index * 10)]
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
        repeatDelay: index * 0.4,
        ease: "easeOut"
      }}
      className="absolute"
    >
      <div className="text-yellow-500 flex items-center justify-center">
        <Coins className="w-6 h-6" />
      </div>
    </motion.div>
  );
}

export function ReferralFomo() {
  const [showPopup, setShowPopup] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {/* Floating Button */}
      <motion.div
        animate={{ 
          y: [-2, 2, -2],
          rotate: [-1, 1, -1]
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        onClick={() => setShowPopup(true)}
        className="fixed bottom-8 right-4 sm:right-8 z-50 cursor-pointer"
      >
        <div className="relative">
          {/* Animated rainbow border */}
          <div className="absolute -inset-0.5 bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 rounded-lg blur opacity-75 group-hover:opacity-100 animate-gradient-xy" />
          
          {/* Animated background glow */}
          <motion.div
            animate={{
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: "reverse"
            }}
            className="absolute inset-0 bg-blue-500/30 rounded-lg filter blur-xl"
          />

          {/* Floating coins */}
          <div className="absolute -top-4 -left-2 w-full h-full">
            {floatingCoins.map((_, index) => (
              <CoinAnimation key={index} index={index} />
            ))}
          </div>

          {/* Main button */}
          <div className="relative bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 text-white px-6 py-4 rounded-lg shadow-[0_0_15px_rgba(59,130,246,0.5)] hover:shadow-[0_0_30px_rgba(59,130,246,0.7)] transform hover:scale-105 transition-all duration-300">
            <div className="absolute inset-0 bg-gradient-to-r from-yellow-400/20 via-yellow-500/20 to-yellow-600/20 rounded-lg animate-pulse" />
            
            {/* Notification dot */}
            <div className="absolute -top-2 -right-2">
              <span className="flex h-5 w-5">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-5 w-5 bg-red-500"></span>
              </span>
            </div>

            {/* Money icon */}
            <motion.div
              animate={isHovered ? {
                rotate: [0, -10, 10, -10, 10, 0],
                scale: [1, 1.1, 1.1, 1.1, 1.1, 1],
              } : {
                rotate: [-5, 5, -5],
                scale: [1, 1.05, 1],
              }}
              transition={{ duration: isHovered ? 0.5 : 2, repeat: Infinity }}
              className="absolute -left-4 -top-4 bg-gradient-to-r from-yellow-500 to-yellow-600 rounded-full p-2.5 shadow-lg"
            >
              <DollarSign className="w-6 h-6 text-white" />
            </motion.div>

            <div className="relative">
              <p className="text-base sm:text-lg font-bold">
                Câștigă 50€ pentru fiecare membru adus!
              </p>
              <motion.p 
                animate={{
                  scale: [1, 1.05, 1]
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                className="text-sm text-yellow-300 mt-1 font-medium"
              >
                Ofertă limitată! Începe acum →
              </motion.p>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Popup Modal */}
      <AnimatePresence>
        {showPopup && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gradient-to-b from-gray-900 to-gray-800 rounded-xl p-6 max-w-md w-full relative overflow-hidden"
            >
              {/* Background effects */}
              <div className="absolute inset-0 bg-gradient-to-r from-yellow-500/10 via-yellow-400/5 to-yellow-300/10" />
              <div className="absolute top-0 inset-x-0 h-px bg-gradient-to-r from-transparent via-yellow-500/50 to-transparent" />
              <div className="absolute bottom-0 inset-x-0 h-px bg-gradient-to-r from-transparent via-yellow-500/50 to-transparent" />

              <button
                onClick={() => setShowPopup(false)}
                className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors"
                aria-label="Închide fereastra"
              >
                <X size={24} />
              </button>

              <div className="relative">
                <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-200 mb-4">
                  💰 Sistem de Recomandare Premium
                </h2>

                <div className="space-y-4 text-gray-300">
                  <p className="text-lg">
                    Transformă-ți rețeaua în venit pasiv! Devino partener Oameni Speciali și câștigă recomandând platforma noastră.
                  </p>

                  <div className="bg-white/5 p-4 rounded-lg border border-yellow-500/20">
                    <h3 className="font-semibold text-yellow-400 mb-2">Beneficii Garantate:</h3>
                    <ul className="list-none space-y-2">
                      <li className="flex items-center gap-2">
                        <Coins className="w-5 h-5 text-yellow-500" />
                        <span>50€ pentru fiecare recomandare</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <DollarSign className="w-5 h-5 text-yellow-500" />
                        <span>Plată rapidă și sigură</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-xl">🎯</span>
                        <span>Cod unic de referral personalizat</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-xl">⚡</span>
                        <span>Tracking în timp real al câștigurilor</span>
                      </li>
                    </ul>
                  </div>

                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => {
                      setShowPopup(false);
                      setShowRegistrationModal(true);
                    }}
                    className="w-full bg-gradient-to-r from-yellow-500 to-yellow-600 text-black py-3 rounded-lg font-semibold hover:shadow-lg transition-all duration-300 mt-4 relative overflow-hidden group"
                  >
                    <div className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/30 to-white/0 animate-shimmer" />
                    <span className="relative flex items-center justify-center gap-2">
                      Începe să Câștigi Acum
                      <DollarSign className="w-5 h-5 group-hover:rotate-12 transition-transform" />
                    </span>
                  </motion.button>

                  <p className="text-sm text-yellow-500/80 text-center">
                    Nu rata această oportunitate unică de a câștiga!
                  </p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <RegistrationModal 
        isOpen={showRegistrationModal} 
        onClose={() => setShowRegistrationModal(false)} 
      />
    </>
  );
}
