import React from 'react';
import { Construction } from 'lucide-react';

interface PlaceholderSectionProps {
  title: string;
}

const PlaceholderSection: React.FC<PlaceholderSectionProps> = ({ title }) => {
  return (
    <div className="min-h-[400px] flex flex-col items-center justify-center">
      <div className="bg-white/5 p-8 rounded-lg border border-white/10 text-center max-w-2xl">
        <Construction className="w-16 h-16 text-blue-400 mx-auto mb-4" />
        <h2 className="text-2xl font-semibold text-white mb-4">
          {title}
        </h2>
        <p className="text-gray-400">
          Această secțiune este în curs de dezvoltare. Lucrăm la implementarea unor 
          funcționalități avansate pentru a vă oferi o experiență completă de administrare.
        </p>
        <div className="mt-6 text-sm text-gray-500">
          Reveniți în curând pentru actualizări.
        </div>
      </div>
    </div>
  );
};

export default PlaceholderSection;
