import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';

interface RegistrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  inviterId?: string;
}

interface FormData {
  fullName: string;
  phone: string;
  email: string;
  country: string;
  city: string;
}

interface ApiResponse {
  success?: boolean;
  message?: string;
  position?: number;
  error?: string;
}

export function RegistrationModal({ isOpen, onClose, inviterId }: RegistrationModalProps) {
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    phone: '',
    email: '',
    country: '',
    city: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [position, setPosition] = useState<number | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      // Register the user
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          referrerId: inviterId
        }),
      });

      const data: ApiResponse = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Înregistrarea a eșuat. Te rugăm să încerci din nou.');
      }

      // Send confirmation email
      await fetch('/api/send-confirmation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          name: formData.fullName,
        }),
      });

      setSuccess(true);
      if (data.position) {
        setPosition(data.position);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'A apărut o eroare. Te rugăm să încerci din nou.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4 sm:p-6"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          onClick={(e) => e.stopPropagation()}
          className="bg-gradient-to-b from-gray-900 to-gray-800 rounded-xl border border-white/10 p-6 sm:p-8 w-full max-w-lg relative"
        >
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors"
            aria-label="Închide formularul"
            title="Închide"
          >
            <X size={24} />
          </button>

          {success ? (
            <div className="text-center py-8">
              <h2 className="text-2xl font-bold text-white mb-4">
                🎉 Felicitări!
              </h2>
              <p className="text-gray-300 mb-4">
                Te-ai înscris cu succes în lista de așteptare.
              </p>
              {position && (
                <p className="text-rose-500 font-bold mb-6">
                  Ești pe poziția #{position} în listă!
                </p>
              )}
              <p className="text-gray-300 mb-2">
                Am trimis un email de confirmare la adresa {formData.email}.
              </p>
              <p className="text-gray-300 mb-6">
                Te vom ține la curent cu toate noutățile despre lansarea platformei!
              </p>
              <button
                onClick={onClose}
                className="px-6 py-3 bg-gradient-to-r from-rose-500 to-rose-600 text-white rounded-lg transition-all hover:shadow-lg hover:shadow-rose-500/20"
              >
                Închide
              </button>
            </div>
          ) : (
            <>
              <h2 className="text-2xl font-bold text-white mb-6">
                Înscrie-te în Lista de Așteptare
              </h2>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="fullName" className="block text-sm font-medium text-gray-300 mb-1">
                    Nume Complet *
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    required
                    value={formData.fullName}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent text-white placeholder-gray-400"
                    placeholder="John Doe"
                  />
                </div>

                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-300 mb-1">
                    Număr de Telefon *
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    required
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent text-white placeholder-gray-400"
                    placeholder="+40 123 456 789"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
                    Adresă de Email *
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent text-white placeholder-gray-400"
                    placeholder="john@example.com"
                  />
                </div>

                <div>
                  <label htmlFor="country" className="block text-sm font-medium text-gray-300 mb-1">
                    Țară *
                  </label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    required
                    value={formData.country}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent text-white placeholder-gray-400"
                    placeholder="România"
                  />
                </div>

                <div>
                  <label htmlFor="city" className="block text-sm font-medium text-gray-300 mb-1">
                    Oraș *
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    required
                    value={formData.city}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent text-white placeholder-gray-400"
                    placeholder="București"
                  />
                </div>

                {error && (
                  <div className="text-rose-500 text-sm mt-2">
                    {error}
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full px-6 py-3 bg-gradient-to-r from-rose-500 to-rose-600 text-white rounded-lg transition-all hover:shadow-lg hover:shadow-rose-500/20 disabled:opacity-50 disabled:cursor-not-allowed mt-6"
                >
                  {isSubmitting ? 'Se procesează...' : 'Înscrie-te Acum'}
                </button>
              </form>
            </>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
