import React from 'react';
import { motion } from 'framer-motion';

export const HeroLogo: React.FC = () => {
  return (
    <div className="relative w-full max-w-[280px] sm:max-w-[500px] mx-auto mb-8 sm:mb-16">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          duration: 1,
          ease: "easeOut",
          type: "spring",
          stiffness: 100
        }}
        className="relative w-full aspect-square"
      >
        <div className="absolute inset-0 rounded-full animate-spin-slow">
          <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500 via-indigo-500 to-blue-500 opacity-75 blur-lg" />
        </div>
        
        <div className="absolute inset-0 bg-white/10 rounded-full backdrop-blur-xl border border-white/20 shadow-lg shadow-blue-500/20">
          <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500/20 to-indigo-500/20 animate-pulse-slow" />
        </div>
        
        <motion.img
          src="/oameni-speciali-logo.png"
          alt="Oameni Speciali"
          className="w-full h-full object-contain p-6 sm:p-10 relative z-10 drop-shadow-[0_0_25px_rgba(255,255,255,0.3)] hover:drop-shadow-[0_0_35px_rgba(147,197,253,0.4)] transition-all duration-300"
          whileHover={{ scale: 1.02 }}
          animate={{
            filter: ["drop-shadow(0 0 20px rgba(255,255,255,0.2))", "drop-shadow(0 0 30px rgba(147,197,253,0.4))"],
          }}
          transition={{
            filter: {
              duration: 2,
              repeat: Infinity,
              repeatType: "reverse"
            }
          }}
        />
      </motion.div>
    </div>
  );
};
