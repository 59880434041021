import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Users, Brain, Shield, Trophy } from 'lucide-react';

const features = [
  {
    icon: Users,
    title: 'Comunitate Exclusivă',
    description: 'Conectează-te cu profesioniști și experți verificați în domeniul sănătății mintale.'
  },
  {
    icon: Brain,
    title: 'Expertiză Validată',
    description: 'Acces la resurse și informații verificate de specialiști cu experiență.'
  },
  {
    icon: Shield,
    title: 'Mediu Sigur',
    description: 'Platformă securizată cu focus pe confidențialitate și respect reciproc.'
  },
  {
    icon: Trophy,
    title: 'Dezvoltare Continuă',
    description: 'Programe și resurse actualizate constant pentru evoluția ta personală.'
  }
];

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

export function Features() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <motion.div
      ref={ref}
      variants={container}
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-4"
    >
      {features.map((feature, index) => (
        <motion.div
          key={index}
          variants={item}
          className="group relative bg-white/5 backdrop-blur-lg rounded-lg p-6 hover:bg-white/10 transition-all duration-300"
        >
          <div className="absolute inset-0 bg-gradient-to-br from-rose-500/10 to-purple-600/10 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          <feature.icon className="w-10 h-10 text-rose-500 mb-4 relative z-10" />
          <h3 className="text-xl font-semibold mb-2 relative z-10">{feature.title}</h3>
          <p className="text-gray-400 relative z-10">{feature.description}</p>
        </motion.div>
      ))}
    </motion.div>
  );
}